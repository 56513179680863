<template>
  <div>
    <div class="is-flex is-justify-content-space-between mb-4">
      <div><span>Subtotal</span></div>
      <div><span v-text="subTotalFormatted"/></div>
    </div>
    <hr/>
    <div class="is-flex is-justify-content-space-between mb-4">
      <div><span class="title is-6">Grand total</span></div>
      <div><span class="title is-6" v-text="grandTotalFormatted"/></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SinglePayDetail",
  props: {
    subTotal: {type: Number, required: true},
    grandTotal: {type: Number, required: true},
    country: {type: String, required: true},
    currency: {type: String, required: true},
    locale: {type: String, required: true},
  },
  computed: {
    priceFormatter() {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency.toUpperCase(),

        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: false,
      })
    },
    subTotalFormatted() {
      return this.priceFormatter.format(this.subTotal / 100)
    },
    grandTotalFormatted() {
      return this.priceFormatter.format(this.grandTotal / 100)
    },
  }
}
</script>

<style scoped>

</style>
