<template>
  <div class="container">
    <div style="margin-top: 30px">
      <div>
        <smile-media-card>
          <template v-slot:card-content>
            <h4 class="title is-5 has-text-left mb-3">
              How do you want to check out?
            </h4>
            <payment-method-ready
              v-if="isPaymentMethodSelected"
              :event-bus="eventBus"
              :is-express-pay-authorized="isExpressPayAuthorized"
              :payment-method="paymentMethod"
            />
            <div v-show="!isPaymentMethodSelected">
              <h6
                v-if="!isPaymentMethodSelected"
                class="subtitle has-text-left mb-3"
              >
                Saved payment methods
              </h6>
              <div v-if="useIFrame" class="iframe-container">
                <iframe
                  id="wallet-ui-iframe"
                  :src="buildWalletUrl"
                  style="
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                  "
                  frameborder="0"
                  width="100%"
                  height="100%"
                  allowfullscreen
                  allow="autoplay"
                />
              </div>

              <wallet-ui
                v-else-if="paymentBrokerAccountId"
                :paymentBrokerAccountID="paymentBrokerAccountId"
                :merchantInitiated="merchantInitiated"
                :locale="locale"
                :countryCode="countryCode"
                :currencyCode="currencyCode"
                :env="env"
                :authClient="authClient"
                :targetDomain="targetDomain"
              />
              <br />
              <expresspay-ui
                v-if="paymentPlan && paymentBrokerAccountId"
                :pbAccountId="paymentBrokerAccountId"
                :amount="paymentPlan.amount"
                :country="countryCode"
                :currency="currencyCode"
                :env="env"
                :authClient="authClient"
                @payment-authorization-success="onPaymentAuthorizationSuccess"
                @payment-authorization-failure="onPaymentAuthorizationFailure"
                @payment-change-requested="onPaymentChangeRequested"
                @payment-capture-request-success="onPaymentCaptureSuccess"
                @payment-capture-request-failure="onPaymentCaptureFailure"
              />
            </div>
          </template>
        </smile-media-card>

        <address-form v-show="isPaymentMethodSelected" ref="addressForm" />
        <smile-pay-summary
          v-if="isSmilePay && isPaymentMethodSelected"
          :grand-total="grandTotal"
          :monthly-payment="monthlyPayment"
          :down-payment="downPayment"
          :country="countryCode"
          :currency="currencyCode"
          :locale="locale"
          style="margin-top: 20px"
        />
      </div>
    </div>
    <div>
      <div>
        <button
          v-show="isPaymentMethodSelected"
          @click="$emit('back')"
          class="button is-primary is-rounded is-fullwidth mt-6"
          :disabled="disableBtn"
        >
          Back to Selection
        </button>
      </div>
      <div>
        <button
          @click="selectPaymentMethod"
          class="button is-primary is-rounded is-fullwidth mt-6"
          :disabled="disableBtn"
        >
          {{ disableBtn ? "Processing..." : "Continue to Review" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { WalletUI } from "@smiledirectclub/sdc.wallet.ui";
import { ExpressPayUI } from "@smiledirectclub/sdc.express-pay.ui";
import SmilePaySummary from "@/components/SmilePaySummary";
import PaymentMethodReady from "@/components/PaymentMethodReady";
import AddressForm from "@/components/AddressForm";
  

export default {
  name: "PaymentStep",
  components: {
    AddressForm,
    SmilePaySummary,
    PaymentMethodReady,
    "wallet-ui": WalletUI,
    "expresspay-ui": ExpressPayUI,
  },
  props: {
    authClient: { type: Object, required: false },
    paymentPlan: { type: Object, required: false },
    paymentMethod: { type: Object, required: false },
    countryCode: { type: String, required: true },
    currencyCode: { type: String, required: true },
    locale: { type: String, required: true },
    eventBus: { required: true },
    paymentBrokerAccountId: { type: String, required: true}
  },
  data() {
    return {
      isSubmitting: false,
      merchantInitiated: false
    };
  },
  computed: {
    targetDomain() {
      return window.location.href;
    },
    getAuthClient() {
      return this.authClient
    },
    disableBtn() {
      return this.isSubmitting === true;
    },
    buildWalletDomain() {
      return `https://wallet-${process.env.VUE_APP_ENVIRONMENT}.smiledirectclub.com`;
    },
    buildWalletUrl() {
      return "";
    },
    useIFrame() {
      return process.env.VUE_APP_USE_IFRAME === "true";
    },
    env() {
      console.log(
        "env",
        `${process.env.VUE_APP_ENVIRONMENT}`,
        process.env.VUE_APP_ENVIRONMENT,
        process.env.NODE_ENV
      );
      return `${process.env.VUE_APP_ENVIRONMENT}`;
    },
    downPayment() {
      return this.paymentPlan ? this.paymentPlan.downPayment : "";
    },
    monthlyPayment() {
      return this.paymentPlan ? this.paymentPlan.monthlyPayment : "";
    },
    grandTotal() {
      return this.paymentPlan
        ? this.paymentPlan.totalPayment || this.paymentPlan.amount
        : "";
    },
    isSmilePay() {
      return this.paymentPlan
        ? this.paymentPlan.financingProvider === "TurnKey" ||
            this.paymentPlan.financingProvider === "HFD"
        : false;
    },
    isPaymentMethodSelected() {
      return this.paymentMethod
        ? this.paymentMethod.isPaymentMethodSelected === true ||
            this.isExpressPayAuthorized
        : false;
    },
    isExpressPayAuthorized() {
      return !!this.paymentMethod && !!this.paymentMethod.expressPaymentType;
    },
  },
  methods: {
    getAccessToken() {
      return localStorage.getItem("GuestToken");
    },
    onPaymentMethodChange(event) {
      this.$emit("onChangePaymentMethod", event);
      console.log("Is Payment Method Selected?", event.detail);
    },
    async _selectPaymentMethodOnSubmit() {
      // Here we are dynamically creating a 'once' time event-listener. This will be used to halt the for submission
      // until the promise is resolved.
      return await new Promise((resolve, reject) => {
        // here we need to create a nested function so that the `addEventListener` and `removeEventListener` will
        // have the same parameter signature between calls; if this is not done then it might result in multiple event
        // listeners being registered.
        function _onSelectPaymentMethodSubmit(event) {
          if (
            event &&
            event.data &&
            event.data.eventType === "wallet-ui-payment-method-change"
          ) {
            window.removeEventListener("message", _onSelectPaymentMethodSubmit);
            if (!!event.data.isPaymentMethodSelected === false) {
              reject("Payment method not selected.");
            } else {
              resolve(event.data);
            }
          }
        }

        window.addEventListener("message", _onSelectPaymentMethodSubmit, false);

        if (this.useIFrame) {
          const iframeWind =
            document.getElementById("wallet-ui-iframe").contentWindow;
          iframeWind.postMessage(
            { eventType: "wallet-ui-select-payment-method" },
            this.buildWalletDomain
          );
        } else {
          window.postMessage({ eventType: "wallet-ui-select-payment-method" });
        }
      });
    },
    async selectPaymentMethod() {
      if (this.isPaymentMethodSelected) {
        const isValidForm = await this.$refs.addressForm.isValidForm();
        if (!isValidForm) return;
        return this.$emit("next", this.$refs.addressForm.address);
      }

      this.isSubmitting = true;

      this._selectPaymentMethodOnSubmit()
        .then(() => {
          console.log("Wallet payment method added.");
        })
        .catch((err) => {
          if (!this.isPaymentMethodSelected) {
            console.log("SOMETHING WENT WRONG!", err);
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    onPaymentAuthorizationSuccess(event) {
      console.log("onPaymentAuthorizationSuccess", event);
      this.showExpressPayErrorMessage = false;

      this.$emit("express-payment-method-success", event);
      this.mapAddressFormFields(event);
    },
    onPaymentAuthorizationFailure(event) {
      console.log("onPaymentAuthorizationFailure", event);
    },
    onPaymentChangeRequested(event) {
      console.log("onPaymentChangeRequested", event);
      this.eventBus.$emit("payment-method-change-requested", {
        notifyComponents: false,
      });
    },
    onPaymentCaptureSuccess(event) {
      console.log("onPaymentCaptureSuccess", event);
      this.$emit("express-payment-created", event);

      // notify Review step component to transit to Summary step
      this.eventBus.$emit("payment-capture-request-success");
    },
    onPaymentCaptureFailure(event) {
      console.log("onPaymentCaptureFailure", event);

      // notify Review step component to display error
      this.eventBus.$emit("payment-capture-request-failure", event);
    },
    mapAddressFormFields(event) {
      const addressFormRef = this.$refs.addressForm.address;
      addressFormRef.name = `${event.firstName} ${event.lastName}`;
      addressFormRef.address1 = event.address1;
      addressFormRef.address2 = event.address2;
      addressFormRef.city = event.city;
      addressFormRef.region = event.region;
      addressFormRef.postalCode = event.postalCode;
    },
  },
};
</script>

<style scoped></style>
