<template>
  <div>
    <wallet-payment-method-ready
      v-if="paymentMethod && paymentMethod.isPaymentMethodSelected && !isExpressPayAuthorized"
      :selected-payment-method="paymentMethod['paymentMethod']"
      @change-payment-method="onPaymentChangeRequested"
    />
    <express-payment-method-ready
      v-if="paymentMethod && isExpressPayAuthorized"
      :selected-payment-method="paymentMethod.expressPaymentType"
      :customer-email="paymentMethod.customerEmail"
      @change-payment-method="onPaymentChangeRequested"
    />
  </div>
</template>

<script>
import { PaymentMethodReady as WalletPaymentMethodReady } from '@smiledirectclub/sdc.wallet.ui';
import { PaymentMethodReady as ExpressPaymentMethodReady } from '@smiledirectclub/sdc.express-pay.ui';

export default {
  name: "PaymentMethodReady",
  components: {
    WalletPaymentMethodReady,
    ExpressPaymentMethodReady,
  },
  props: {
    paymentMethod: {type: Object, required: true},
    isExpressPayAuthorized: {type: Boolean, required: true},
    eventBus: {required: true},
  },
  methods: {
    onPaymentChangeRequested() {
      this.eventBus.$emit('payment-method-change-requested', { notifyComponents: true });
    }
  },
}
</script>
