var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "is-flex is-justify-content-space-between mb-4" },
      [
        _vm._m(0),
        _c("div", [
          _c("span", {
            domProps: { textContent: _vm._s(_vm.downPaymentFormatted) },
          }),
        ]),
      ]
    ),
    _c("hr"),
    _c(
      "div",
      { staticClass: "is-flex is-justify-content-space-between mb-4" },
      [
        _vm._m(1),
        _c("div", [
          _c("span", {
            staticClass: "title is-6",
            domProps: { textContent: _vm._s(_vm.dueTodayFormatted) },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("span", [_vm._v("Down Payment")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("span", { staticClass: "title is-6" }, [_vm._v("Due today")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }