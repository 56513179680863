var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("smile-media-card", {
        staticStyle: { "margin-top": "20px" },
        scopedSlots: _vm._u([
          {
            key: "card-content",
            fn: function () {
              return [
                _c(
                  "h4",
                  {
                    staticClass: "title is-4 has-text-left",
                    attrs: { id: "wallet-ui-payment-label" },
                  },
                  [_vm._v(" Order Summary ")]
                ),
                _c("strong", [
                  _vm._v("Payment ID: " + _vm._s(_vm.payment.paymentId)),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("smile-media-card", {
        staticStyle: { "margin-top": "10px" },
        scopedSlots: _vm._u([
          {
            key: "card-content",
            fn: function () {
              return [
                _vm.showCatButton ? _c("div", [_c("cat-step")], 1) : _vm._e(),
                _c(
                  "h4",
                  {
                    staticClass: "title is-5 has-text-left",
                    attrs: { id: "wallet-ui-cat-label" },
                  },
                  [_vm._v(" Cat button (Push me) ")]
                ),
                _c("a", { staticClass: "image-button", attrs: { href: "#" } }, [
                  _c("img", {
                    attrs: {
                      src: require("../assets/fold-sitting.png"),
                      alt: "Button Image",
                    },
                    on: {
                      click: () => (_vm.showCatButton = !_vm.showCatButton),
                    },
                  }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }