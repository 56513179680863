<template>
  <div class="container" style="margin-top: 20px">
    <div class="columns">
      <div class="column is-6">
        <smile-media-card style="margin-bottom: 10px">
          <template v-slot:card-content>
            <h4 id="contact-label" class="title is-5 has-text-left">Contact</h4>
            <p>{{ address.name }}</p>
          </template>
        </smile-media-card>
        <smile-media-card style="margin-bottom: 10px">
          <template v-slot:card-content>
            <h4 id="shipping-label" class="title is-5 has-text-left">
              Shipping address
            </h4>
            <p>{{ formatAddress }}</p>
            <smile-field
              style="font-weight: bold"
              id="shipping-change-link-field"
              data-orion-id="shipping-change-link-field"
            >
              <a
                id="shipping-change-link"
                data-orion-id="shipping-change-link"
                @click="$emit('back')"
              >
                Change
              </a>
            </smile-field>
          </template>
        </smile-media-card>
        <smile-media-card style="margin-bottom: 10px">
          <template v-slot:card-content>
            <h4
              id="wallet-ui-payment-label"
              class="title is-5 has-text-left mb-3"
            >
              Payment
            </h4>
            <payment-method-ready
              :payment-method="paymentMethod"
              :is-express-pay-authorized="isExpressPayAuthorized"
              :event-bus="eventBus"
            />
            <h4
              id="wallet-ui-billing-label"
              class="title is-5 has-text-left"
              style="margin-top: 25px"
            >
              Billing
            </h4>
            <p>Billing address same as shipping</p>
          </template>
        </smile-media-card>

        <button
          v-if="isSmilePay"
          @click="downloadAgreement"
          class="button is-primary is-rounded is-fullwidth mt-6"
          :disabled="isSubmitting"
        >
          Download Finance Agreement
        </button>
      </div>
      <div class="column is-6">
        <smile-pay-summary
          v-if="isSmilePay"
          :grand-total="grandTotal"
          :monthly-payment="monthlyPayment"
          :down-payment="downPayment"
          :country="countryCode"
          :currency="currencyCode"
          :locale="locale"
          style="margin-bottom: 20px"
        />
        <order-summary
          v-if="!!paymentPlan"
          :payment-plan="paymentPlan"
          style="margin-bottom: 20px"
        />

        <div v-if="showErrorMessage" class="mt-8">
          <article class="message is-danger">
            <div class="message-body">
              <smile-icon
                icon="alert-triangle"
                size="is-medium"
                type="is-danger"
              />
              <span>
                There was a problem with your payment. You have not been
                charged. Please try again with a different card or payment
                method.</span
              >
            </div>
          </article>
        </div>

        <button
          @click="$emit('back')"
          class="button is-primary is-rounded is-fullwidth mb-6"
          :disabled="isSubmitting"
        >
          Back to Selection
        </button>

        <button
          @click="placeOrder"
          class="button is-primary is-rounded is-fullwidth mb-6"
          :disabled="isSubmitting"
        >
          {{ isSubmitting ? "Processing..." : "Purchase" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PaymentMethodReady from "@/components/PaymentMethodReady";
import OrderSummary from "@/components/OrderSummary";
import SmilePaySummary from "@/components/SmilePaySummary";

export default {
  name: "ReviewStep",
  components: {
    OrderSummary,
    SmilePaySummary,
    PaymentMethodReady,
  },
  props: {
    paymentMethod: { type: Object, required: true },
    paymentPlan: { type: Object, required: false },
    countryCode: { type: String, required: true },
    currencyCode: { type: String, required: true },
    locale: { type: String, required: true },
    isExpressPayAuthorized: { type: Boolean, required: true },
    eventBus: { required: true },
    address: { type: Object, required: true },
    applicationId: { type: String },
    fsClient: { type: Object },
  },
  data() {
    return {
      isSubmitting: false,
      showErrorMessage: false,
    };
  },
  computed: {
    buildWalletDomain() {
      return `https://wallet-${process.env.VUE_APP_ENVIRONMENT}.smiledirectclub.com`;
    },
    useIFrame() {
      return process.env.VUE_APP_USE_IFRAME === "true";
    },
    downPayment() {
      return this.paymentPlan ? this.paymentPlan.downPayment : "";
    },
    monthlyPayment() {
      return this.paymentPlan ? this.paymentPlan.monthlyPayment : "";
    },
    grandTotal() {
      return this.paymentPlan
        ? this.paymentPlan.totalPayment || this.paymentPlan.amount
        : "";
    },
    isSmilePay() {
      return this.paymentPlan
        ? this.paymentPlan.financingProvider === "TurnKey" ||
            this.paymentPlan.financingProvider === "HFD"
        : false;
    },
    formatAddress() {
      return this.address
        ? [
            this.address.address1,
            this.address.address2,
            this.address.city,
            this.address.region,
            ].filter(Boolean).join(", ") + " " + this.address.postalCode : null;
    },
  },
  methods: {
    getAccessToken() {
      return localStorage.getItem("GuestToken");
    },
    downloadAgreement() {
      this.getLoanAgreement();
    },
    async getLoanAgreement() {
      const response = await this.fsClient.getAgreement(this.applicationId);
      const downloadLink = document.createElement("a");

      downloadLink.href = `data:application/pdf;base64,${response.content}`;
      downloadLink.download = response.file_name;
      downloadLink.click();
    },
    async _makePaymentOnSubmit() {
      return await new Promise((resolve, reject) => {
        function _onMakePaymentSubmit(event) {
          if (
            event &&
            event.data &&
            event.data.eventType === "wallet-ui-payment-success"
          ) {
            window.removeEventListener("message", _onMakePaymentSubmit);
            resolve(event);
          } else if (
            event &&
            event.data &&
            event.data.eventType === "wallet-ui-payment-failure"
          ) {
            window.removeEventListener("message", _onMakePaymentSubmit);
            reject("Payment failure");
          }
        }

        // Here we are dynamically creating a 'once' time event-listener. This will be used to halt the for submission
        // until the promise is resolved.
        window.addEventListener("message", _onMakePaymentSubmit, false);

        const payload = {
          eventType: "wallet-ui-make-payment",
          detail: {
            amount: this.paymentPlan.downPayment || this.paymentPlan.amount,
            metadata: {
              host: "example-checkout",
              env: process.env.VUE_APP_ENVIRONMENT,
            },
          },
        };

        if (this.useIFrame) {
          const iframeWind =
            document.getElementById("wallet-ui-iframe").contentWindow;
          iframeWind.postMessage(payload, this.buildWalletDomain);
        } else {
          window.postMessage(payload);
        }
      });
    },
    async _makeExpressPaymentOnSubmit(eventBus) {
      return await new Promise((resolve, reject) => {
        function _onExpressPaymentCaptureSuccess(event) {
          eventBus.$off(
            "payment-capture-request-success",
            _onExpressPaymentCaptureSuccess
          );
          eventBus.$off(
            "payment-capture-request-failure",
            _onExpressPaymentCaptureFailure
          );
          resolve(event);
        }

        function _onExpressPaymentCaptureFailure(event) {
          eventBus.$off(
            "payment-capture-request-success",
            _onExpressPaymentCaptureSuccess
          );
          eventBus.$off(
            "payment-capture-request-failure",
            _onExpressPaymentCaptureFailure
          );
          reject(event);
        }

        // Here we are dynamically creating a 'once' time event-listeners. This will be used to halt the for submission
        // until the promise is resolved.
        eventBus.$on(
          "payment-capture-request-success",
          _onExpressPaymentCaptureSuccess
        );
        eventBus.$on(
          "payment-capture-request-failure",
          _onExpressPaymentCaptureFailure
        );

        window.dispatchEvent(
          new CustomEvent("payment-submitted", {
            detail: {
              chargeAmount: this.grandTotal,
              paymentBrokerPaymentMethodId:
                this.paymentMethod.paymentBrokerPaymentMethodId,
            },
          })
        );
      });
    },
    placeOrder() {
      this.isSubmitting = true;
      this.showErrorMessage = false;
      if (!this.isExpressPayAuthorized) {
        this._makePaymentOnSubmit()
          .then(() => this.$emit("next"))
          .catch((err) => {
            this.showErrorMessage = true;
            console.log("SOMETHING WENT WRONG!", err);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      } else {
        this._makeExpressPaymentOnSubmit(this.eventBus)
          .then(() => this.$emit("next"))
          .catch((err) => {
            this.showErrorMessage = true;
            console.log("SOMETHING WENT WRONG!", err);
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>

<style scoped></style>
