<template>
  <div class="container">
    <smile-media-card style="margin-top: 20px;">
      <template v-slot:card-content>
        <h4 id="wallet-ui-payment-label" class="title is-4 has-text-left">
          Order Summary
        </h4>
        <strong>Payment ID: {{ payment.paymentId }}</strong>
      </template>
    </smile-media-card>

    <smile-media-card style="margin-top: 10px">
        <template v-slot:card-content>
          <div v-if="showCatButton">
        <cat-step />
      </div>
          <h4 id="wallet-ui-cat-label" class="title is-5 has-text-left">
            Cat button (Push me)
          </h4>
          <a href="#" class="image-button">
            <img @click="() => showCatButton = !showCatButton" src="../assets/fold-sitting.png" alt="Button Image">
          </a>
        </template>
      </smile-media-card>
  </div>
</template>

<script>

import CatStep from "./CatStep.vue";

export default {
  name: "ReviewStep",
  components: { CatStep },
  props: {
    payment: { type: Object },
  },
  data() {
    return {
      showCatButton: false
    };
  }
}
</script>

<style scoped>
.image-button img {
  display: block;
  width: 30%;
  height: auto;
}
</style>
