<template>
  <smile-media-card>
    <template v-slot:card-content>
      <h4 id="wallet-ui-payment-label" class="title is-5 has-text-left">
        SmilePay<sup>TM</sup> Payment Plan
      </h4>
      <br/>
      <div class="is-flex is-justify-content-space-between mb-4">
        <div><span>Down payment</span></div>
        <div><span v-text="downPaymentFormatted"/></div>
      </div>
      <hr/>
      <div class="is-flex is-justify-content-space-between mb-4">
        <div><span>Monthly payment</span></div>
        <div><span v-text="monthlyPaymentFormatted"/></div>
      </div>
      <hr/>
      <div class="is-flex is-justify-content-space-between mb-4">
        <div><span class="title is-6">Grand total</span></div>
        <div><span class="title is-6" v-text="grandTotalFormatted"/></div>
      </div>
    </template>
  </smile-media-card>

</template>

<script>
export default {
  name: "SmilePaySummary",
  props: {
    downPayment: {type: Number, required: true},
    monthlyPayment: {type: Number, required: true},
    grandTotal: {type: Number, required: true},
    country: {type: String, required: true},
    currency: {type: String, required: true},
    locale: {type: String, required: true},
  },
  computed: {
    priceFormatter() {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency.toUpperCase(),

        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: false,
      })
    },
    downPaymentFormatted() {
      return this.priceFormatter.format(this.downPayment / 100)
    },
    monthlyPaymentFormatted() {
      return this.priceFormatter.format(this.monthlyPayment / 100)
    },
    grandTotalFormatted() {
      return this.priceFormatter.format(this.grandTotal / 100)
    },
  }
}
</script>

<style scoped>

</style>
