var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container mt-20", staticStyle: { "max-width": "800px" } },
    [
      _c(
        "h4",
        {
          staticClass: "title is-5 has-text-left",
          attrs: { id: "smilepay-ui-payment-label" },
        },
        [_vm._v(" One-time payment or SmilePay™? ")]
      ),
      _vm.useIFrame
        ? _c("div", { staticClass: "iframe-container" }, [
            _c("iframe", {
              staticStyle: {
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "0px",
                top: "0px",
              },
              attrs: {
                id: "smilepay-ui-iframe",
                src: _vm.buildSmilePayURL,
                frameborder: "0",
                width: "100%",
                height: "100%",
                allowfullscreen: "",
                allow: "autoplay",
              },
            }),
          ])
        : _c("smilepay-ui", {
            attrs: {
              price: _vm.price,
              productSKU: _vm.productSKU,
              financingPrice: _vm.financingPrice,
              financingProductSKU: _vm.financingProductSKU,
              currency: _vm.currencyCode,
              country: _vm.countryCode,
              env: _vm.environment,
              defaultOption: _vm.selectedOption,
              defaultSubOption: _vm.selectedSubOption,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }