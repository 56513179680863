<template>
  <div>
    <ValidationObserver ref="formObserver">
      <smile-media-card style="margin-top: 20px; border=10px solid red">
        <template v-slot:card-content>
          <h4 id="wallet-ui-payment-label" class="title is-5 has-text-left">
            Shipping address
          </h4>
          <br />
          <div class="has-background-light p-3">
            <ValidationProvider
              v-slot="{ errors }"
              name="name"
              :rules="{ required: true, is_latin: true, max: 100 }"
            >
              <smile-field
                id="address-name-field"
                data-orion-id="address-name-field"
                class="has-text-left"
                label="Name"
                :show-message="Boolean(errors[0])"
                :message="errors[0]"
                :type="Boolean(errors[0]) ? 'is-danger' : ''"
              >
                <smile-input
                  id="address-name-input"
                  data-orion-id="address-name-input"
                  name="address_name"
                  v-model="address.name"
                  placeholder="e.g. John Smith"
                />
              </smile-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="address1"
              :rules="{ required: true, is_latin: true, max: 100 }"
            >
              <smile-field
                id="address1-field"
                data-orion-id="address1-field"
                class="has-text-left"
                label="Street address 1"
                :show-message="Boolean(errors[0])"
                :message="errors[0]"
                :type="Boolean(errors[0]) ? 'is-danger' : ''"
              >
                <smile-input
                  id="address-1"
                  class="has-text-left input-textbox"
                  placeholder="38 Woolnough Road"
                  type="text"
                  v-model="address.address1"
                />
              </smile-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="address2"
              :rules="{ is_latin: true, max: 100 }"
            >
              <smile-field
                id="address2-field"
                data-orion-id="address2-field"
                class="has-text-left"
                label="Street address 2 (optional)"
                :show-message="Boolean(errors[0])"
                :message="errors[0]"
                :type="Boolean(errors[0]) ? 'is-danger' : ''"
              >
                <smile-input
                  id="address-2"
                  class="has-text-left input-textbox"
                  placeholder="Apt 2"
                  type="text"
                  v-model="address.address2"
                />
              </smile-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="city"
              :rules="{ required: true, max: 100 }"
            >
              <smile-field
                id="city-field"
                data-orion-id="city-field"
                class="has-text-left"
                label="City"
                :show-message="Boolean(errors[0])"
                :message="errors[0]"
                :type="Boolean(errors[0]) ? 'is-danger' : ''"
              >
                <smile-input
                  id="city-1"
                  class="has-text-left input-textbox"
                  placeholder="Clapham"
                  type="text"
                  v-model="address.city"
                />
              </smile-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="state"
              :rules="{ required: true, is_latin: true, max: 100 }"
            >
              <smile-field
                id="region-field"
                data-orion-id="region-field"
                class="has-text-left"
                label="Region"
                :show-message="Boolean(errors[0])"
                :message="errors[0]"
                :type="Boolean(errors[0]) ? 'is-danger' : ''"
              >
                <smile-input
                  id="region-field-input"
                  class="has-text-left input-textbox"
                  placeholder="South Australia"
                  type="text"
                  v-model="address.region"
                />
              </smile-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors }"
              name="postal-code"
              :rules="{ required: true }"
            >
              <smile-field
                id="postal-code-field"
                data-orion-id="postal-code-field"
                class="has-text-left"
                label="Postal Code"
                :show-message="Boolean(errors[0])"
                :message="errors[0]"
                :type="Boolean(errors[0]) ? 'is-danger' : ''"
              >
                <smile-input
                  id="postal-code-field"
                  class="has-text-left input-textbox"
                  placeholder="5062"
                  type="text"
                  v-model="address.postalCode"
                />
              </smile-field>
            </ValidationProvider>
          </div>
        </template>
      </smile-media-card>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from "vue";
import { SmileInput, SmileField } from "sdc-component-library";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import validations from "../../validators/validations";

validations();

export default Vue.extend({
  name: "AddressForm",
  components: {
    ValidationObserver,
    ValidationProvider,
    "smile-field": SmileField,
    "smile-input": SmileInput,
  },
  data() {
    return {
      address: {
        name: "",
        address1: "",
        address2: "",
        city: "",
        region: "",
        postalCode: "",
      },
    };
  },
  methods: {
    async isValidForm() {
      const success = await this.$refs.formObserver.validate();
      return success;
    },
  },
});
</script>
<style scoped>
.input-textbox {
  padding-bottom: 10px;
}
</style>
