<template>
  <div class="container mt-20" style="max-width: 800px;">
    <h4 id="smilepay-ui-payment-label" class="title is-5 has-text-left">
      One-time payment or SmilePay&trade;?
    </h4>
    <div v-if="useIFrame" class="iframe-container">
      <iframe id="smilepay-ui-iframe" :src="buildSmilePayURL"
              style="width:100%;height:100%;position:absolute;left:0px;top:0px;"
              frameborder="0" width="100%" height="100%"
              allowfullscreen allow="autoplay"/>
    </div>
    <smilepay-ui
        v-else
        :price="price"
        :productSKU="productSKU"
        :financingPrice="financingPrice"
        :financingProductSKU="financingProductSKU"
        :currency="currencyCode"
        :country="countryCode"
        :env="environment"
        :defaultOption="selectedOption"
        :defaultSubOption="selectedSubOption"
    />
  </div>
</template>

<script>
import {SmilePayUI} from "@smiledirectclub/sdc.smilepay.ui";

export default {
  name: "PaymentOptionStep",
  components: {
    'smilepay-ui': SmilePayUI,
  },
  props: {
    countryCode: {type: String, required: true},
    currencyCode: {type: String, required: true},
    locale: {type: String, required: true},
    product: {type: Object, required: true},
    paymentPlan: {type: Object, required: false}
  },
  computed: {
    buildSmilePayURL() {
      return `https://smilepay-${process.env.VUE_APP_ENVIRONMENT}.smiledirectclub.com/?productSKU=${this.productSKU}&financingProductSKU=${this.financingProductSKU}&price=${this.price}&financingPrice=${this.financingPrice}&country=${this.countryCode}&currency=${this.currencyCode}&targetDomain=${window.location.href}&defaultOption=${this.selectedOption}&defaultSubOption=${this.selectedSubOption}`
    },
    useIFrame() {
      return process.env.VUE_APP_USE_IFRAME === 'true'
    },
    environment() {
      return process.env.VUE_APP_ENVIRONMENT
    },
    productSKU(){
       return this.product.productSKU
    },
    financingProductSKU(){
      return this.product.financingSKU
    },
    price(){
      return this.product.price
    },
    financingPrice(){
      return this.product.financingPrice
    },
    selectedOption(){
      if (this.paymentPlan) {
        return this.paymentPlan.financingProvider ? 'smilepay' : 'singlepay'
      }
      return ''
    },
    selectedSubOption(){
      return this.paymentPlan && this.paymentPlan.financingProvider ? String(this.paymentPlan.months) : ''
    },
  },
}
</script>

<style>
.title.title-slim {
  font-weight: normal !important;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}
</style>
