import { extend } from 'vee-validate';
import {
  required, alpha_spaces, numeric, min, max,
} from 'vee-validate/dist/rules';
import invalid_chars from './rules/invalid_chars';
import is_latin from './rules/is_latin';

export default () => {
  extend('required', {
    ...required,
    message: 'This field is required',
  });

  extend('alpha_spaces', {
    ...alpha_spaces,
    message: 'Provide a valid value',
  });

  extend('numeric', {
    ...numeric,
    message: 'This field must have only numbers',
  });

  extend('min', {
    ...min,
    message: (fieldName, placeholders) => `This field must have no less than ${placeholders.length} characters`,
  });

  extend('max', {
    ...max,
    message: (fieldName, placeholders) => `This field must have no more than ${placeholders.length} characters`,
  });

  extend('invalid_chars', {
    validate: (value) => invalid_chars(value),
    message: 'Invalid characters',
  });

  extend('is_latin', {
    validate: (value) => is_latin(value),
    message: 'Please use only English alphabet, numbers and/or symbols.',
  });
};
