var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.paymentMethod &&
      _vm.paymentMethod.isPaymentMethodSelected &&
      !_vm.isExpressPayAuthorized
        ? _c("wallet-payment-method-ready", {
            attrs: {
              "selected-payment-method": _vm.paymentMethod["paymentMethod"],
            },
            on: { "change-payment-method": _vm.onPaymentChangeRequested },
          })
        : _vm._e(),
      _vm.paymentMethod && _vm.isExpressPayAuthorized
        ? _c("express-payment-method-ready", {
            attrs: {
              "selected-payment-method": _vm.paymentMethod.expressPaymentType,
              "customer-email": _vm.paymentMethod.customerEmail,
            },
            on: { "change-payment-method": _vm.onPaymentChangeRequested },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }