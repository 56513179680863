import Vue from 'vue'
import App from './App.vue'
import OktaVue from '@okta/okta-vue';
import ComponentLibrary from "sdc-component-library";
import {OktaAuth} from "@okta/okta-auth-js";

import 'sdc-component-library/dist/sdc.css';
import router from "@/router";

Vue.config.productionTip = false


if (process.env.VUE_APP_USE_STAFF_OKTA === 'true') {
  const oktaAuth = new OktaAuth({
    clientId: `${process.env.VUE_APP_OICD_CLIENT_ID}`,
    issuer: `https://${process.env.VUE_APP_OICD_OKTA_DOMAIN}`,
    redirectUri: `${window.location.origin}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
  });

  Vue.use(OktaVue, { oktaAuth });
}

Vue.use(ComponentLibrary);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
