<template>
  <div>
    <div class="is-flex is-justify-content-space-between mb-4">
      <div><span>Down Payment</span></div>
      <div><span v-text="downPaymentFormatted"/></div>
    </div>
    <hr/>
    <div class="is-flex is-justify-content-space-between mb-4">
      <div><span class="title is-6">Due today</span></div>
      <div><span class="title is-6" v-text="dueTodayFormatted"/></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmilePayDetail",
  props: {
    downPayment: {type: Number, required: true},
    dueToday: {type: Number, required: true},
    country: {type: String, required: true},
    currency: {type: String, required: true},
    locale: {type: String, required: true},
  },
  computed: {
    priceFormatter() {
      return new Intl.NumberFormat(this.locale, {
        style: 'currency',
        currency: this.currency.toUpperCase(),

        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping: false,
      })
    },
    downPaymentFormatted() {
      return this.priceFormatter.format(this.downPayment / 100)
    },
    dueTodayFormatted() {
      return this.priceFormatter.format(this.dueToday / 100)
    },
  }
}
</script>

<style scoped>

</style>
