var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("smile-media-card", {
    scopedSlots: _vm._u([
      {
        key: "card-content",
        fn: function () {
          return [
            _c(
              "h4",
              {
                staticClass: "title is-5 has-text-left",
                attrs: { id: "wallet-ui-payment-label" },
              },
              [_vm._v(" Order Summary ")]
            ),
            _c("br"),
            _vm._v(" .... "),
            _c("hr"),
            !_vm.isSmilePay
              ? _c("SinglePayDetail", {
                  attrs: {
                    "sub-total": _vm.subTotal,
                    "grand-total": _vm.grandTotal,
                    country: _vm.country,
                    currency: _vm.currency,
                    locale: _vm.locale,
                  },
                })
              : _vm._e(),
            _vm.isSmilePay
              ? _c("SmilePayDetail", {
                  attrs: {
                    locale: _vm.locale,
                    currency: _vm.currency,
                    country: _vm.country,
                    "down-payment": _vm.downPayment,
                    "due-today": _vm.dueToday,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }