var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "ValidationObserver",
        { ref: "formObserver" },
        [
          _c("smile-media-card", {
            staticStyle: { "margin-top": "20px" },
            scopedSlots: _vm._u([
              {
                key: "card-content",
                fn: function () {
                  return [
                    _c(
                      "h4",
                      {
                        staticClass: "title is-5 has-text-left",
                        attrs: { id: "wallet-ui-payment-label" },
                      },
                      [_vm._v(" Shipping address ")]
                    ),
                    _c("br"),
                    _c(
                      "div",
                      { staticClass: "has-background-light p-3" },
                      [
                        _c("ValidationProvider", {
                          attrs: {
                            name: "name",
                            rules: { required: true, is_latin: true, max: 100 },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "smile-field",
                                    {
                                      staticClass: "has-text-left",
                                      attrs: {
                                        id: "address-name-field",
                                        "data-orion-id": "address-name-field",
                                        label: "Name",
                                        "show-message": Boolean(errors[0]),
                                        message: errors[0],
                                        type: Boolean(errors[0])
                                          ? "is-danger"
                                          : "",
                                      },
                                    },
                                    [
                                      _c("smile-input", {
                                        attrs: {
                                          id: "address-name-input",
                                          "data-orion-id": "address-name-input",
                                          name: "address_name",
                                          placeholder: "e.g. John Smith",
                                        },
                                        model: {
                                          value: _vm.address.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.address, "name", $$v)
                                          },
                                          expression: "address.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "address1",
                            rules: { required: true, is_latin: true, max: 100 },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "smile-field",
                                    {
                                      staticClass: "has-text-left",
                                      attrs: {
                                        id: "address1-field",
                                        "data-orion-id": "address1-field",
                                        label: "Street address 1",
                                        "show-message": Boolean(errors[0]),
                                        message: errors[0],
                                        type: Boolean(errors[0])
                                          ? "is-danger"
                                          : "",
                                      },
                                    },
                                    [
                                      _c("smile-input", {
                                        staticClass:
                                          "has-text-left input-textbox",
                                        attrs: {
                                          id: "address-1",
                                          placeholder: "38 Woolnough Road",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.address.address1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "address1",
                                              $$v
                                            )
                                          },
                                          expression: "address.address1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "address2",
                            rules: { is_latin: true, max: 100 },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "smile-field",
                                    {
                                      staticClass: "has-text-left",
                                      attrs: {
                                        id: "address2-field",
                                        "data-orion-id": "address2-field",
                                        label: "Street address 2 (optional)",
                                        "show-message": Boolean(errors[0]),
                                        message: errors[0],
                                        type: Boolean(errors[0])
                                          ? "is-danger"
                                          : "",
                                      },
                                    },
                                    [
                                      _c("smile-input", {
                                        staticClass:
                                          "has-text-left input-textbox",
                                        attrs: {
                                          id: "address-2",
                                          placeholder: "Apt 2",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.address.address2,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "address2",
                                              $$v
                                            )
                                          },
                                          expression: "address.address2",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "city",
                            rules: { required: true, max: 100 },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "smile-field",
                                    {
                                      staticClass: "has-text-left",
                                      attrs: {
                                        id: "city-field",
                                        "data-orion-id": "city-field",
                                        label: "City",
                                        "show-message": Boolean(errors[0]),
                                        message: errors[0],
                                        type: Boolean(errors[0])
                                          ? "is-danger"
                                          : "",
                                      },
                                    },
                                    [
                                      _c("smile-input", {
                                        staticClass:
                                          "has-text-left input-textbox",
                                        attrs: {
                                          id: "city-1",
                                          placeholder: "Clapham",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.address.city,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.address, "city", $$v)
                                          },
                                          expression: "address.city",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "state",
                            rules: { required: true, is_latin: true, max: 100 },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "smile-field",
                                    {
                                      staticClass: "has-text-left",
                                      attrs: {
                                        id: "region-field",
                                        "data-orion-id": "region-field",
                                        label: "Region",
                                        "show-message": Boolean(errors[0]),
                                        message: errors[0],
                                        type: Boolean(errors[0])
                                          ? "is-danger"
                                          : "",
                                      },
                                    },
                                    [
                                      _c("smile-input", {
                                        staticClass:
                                          "has-text-left input-textbox",
                                        attrs: {
                                          id: "region-field-input",
                                          placeholder: "South Australia",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.address.region,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.address, "region", $$v)
                                          },
                                          expression: "address.region",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("ValidationProvider", {
                          attrs: {
                            name: "postal-code",
                            rules: { required: true },
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ errors }) {
                                return [
                                  _c(
                                    "smile-field",
                                    {
                                      staticClass: "has-text-left",
                                      attrs: {
                                        id: "postal-code-field",
                                        "data-orion-id": "postal-code-field",
                                        label: "Postal Code",
                                        "show-message": Boolean(errors[0]),
                                        message: errors[0],
                                        type: Boolean(errors[0])
                                          ? "is-danger"
                                          : "",
                                      },
                                    },
                                    [
                                      _c("smile-input", {
                                        staticClass:
                                          "has-text-left input-textbox",
                                        attrs: {
                                          id: "postal-code-field",
                                          placeholder: "5062",
                                          type: "text",
                                        },
                                        model: {
                                          value: _vm.address.postalCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "postalCode",
                                              $$v
                                            )
                                          },
                                          expression: "address.postalCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }