var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("smile-media-card", {
    scopedSlots: _vm._u([
      {
        key: "card-content",
        fn: function () {
          return [
            _c(
              "h4",
              {
                staticClass: "title is-5 has-text-left",
                attrs: { id: "wallet-ui-payment-label" },
              },
              [
                _vm._v(" SmilePay"),
                _c("sup", [_vm._v("TM")]),
                _vm._v(" Payment Plan "),
              ]
            ),
            _c("br"),
            _c(
              "div",
              { staticClass: "is-flex is-justify-content-space-between mb-4" },
              [
                _c("div", [_c("span", [_vm._v("Down payment")])]),
                _c("div", [
                  _c("span", {
                    domProps: { textContent: _vm._s(_vm.downPaymentFormatted) },
                  }),
                ]),
              ]
            ),
            _c("hr"),
            _c(
              "div",
              { staticClass: "is-flex is-justify-content-space-between mb-4" },
              [
                _c("div", [_c("span", [_vm._v("Monthly payment")])]),
                _c("div", [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.monthlyPaymentFormatted),
                    },
                  }),
                ]),
              ]
            ),
            _c("hr"),
            _c(
              "div",
              { staticClass: "is-flex is-justify-content-space-between mb-4" },
              [
                _c("div", [
                  _c("span", { staticClass: "title is-6" }, [
                    _vm._v("Grand total"),
                  ]),
                ]),
                _c("div", [
                  _c("span", {
                    staticClass: "title is-6",
                    domProps: { textContent: _vm._s(_vm.grandTotalFormatted) },
                  }),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }