import Vue from 'vue';
import VueRouter from 'vue-router';
import { LoginCallback } from '@okta/okta-vue';
import HomePage from "@/components/HomePage";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: HomePage,
        props: (route) => (
            {
                productSKU: route.query.productSKU,
                price: Number(route.query.price),
                financingProductSKU: route.query.financingProductSKU,
                financingPrice: Number(route.query.financingPrice),
                currency: route.query.currency,
                country: route.query.country,
                region: route.query.region,
                targetDomain: route.query.targetDomain,
                paymentBrokerAccountId: route.query.paymentBrokerAccountId
            }),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login/callback',
        component: LoginCallback,
    },

];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
