<template>
  <smile-media-card>
    <template v-slot:card-content>
      <h4 id="wallet-ui-payment-label" class="title is-5 has-text-left">
        Order Summary
      </h4>
      <br/>
      ....
      <hr>
      <SinglePayDetail
          v-if="!isSmilePay"
          :sub-total="subTotal"
          :grand-total="grandTotal"
          :country="country"
          :currency="currency"
          :locale="locale"
      />
      <SmilePayDetail v-if="isSmilePay"
                      :locale="locale" :currency="currency" :country="country"
                      :down-payment="downPayment" :due-today="dueToday"/>
    </template>
  </smile-media-card>
</template>

<script>
import SinglePayDetail from "@/components/SinglePayDetail";
import SmilePayDetail from "@/components/SmilePayDetail";

export default {
  name: "OrderSummary",
  components: {SmilePayDetail, SinglePayDetail},
  props: {
    paymentPlan: {type: Object, required: true},
  },
  computed: {
    isSmilePay() {
      return this.paymentPlan ? this.paymentPlan.financingProvider === "TurnKey" || this.paymentPlan.financingProvider === "HFD" : false
    },
    subTotal() {
      return this.paymentPlan.amount;
    },
    grandTotal() {
      return this.paymentPlan.totalPayment || this.paymentPlan.amount;
    },
    currency() {
      return this.paymentPlan.currency;
    },
    country() {
      return this.paymentPlan.businessEntity;
    },
    downPayment(){
      return this.paymentPlan.downPayment;
    },
    dueToday(){
      return this.paymentPlan.downPayment;
    },
    locale() {
      return "en-AU";
    }
  }
}
</script>

<style scoped>

</style>
