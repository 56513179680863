var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "all-wrap" }, [
      _c("div", { staticClass: "all" }, [
        _c("div", { staticClass: "yarn" }),
        _c("div", { staticClass: "cat-wrap" }, [
          _c("div", { staticClass: "cat" }, [
            _c("div", { staticClass: "cat-upper" }, [
              _c("div", { staticClass: "cat-leg" }),
              _c("div", { staticClass: "cat-leg" }),
              _c("div", { staticClass: "cat-head" }, [
                _c("div", { staticClass: "cat-ears" }, [
                  _c("div", { staticClass: "cat-ear" }),
                  _c("div", { staticClass: "cat-ear" }),
                ]),
                _c("div", { staticClass: "cat-face" }, [
                  _c("div", { staticClass: "cat-eyes" }),
                  _c("div", { staticClass: "cat-mouth" }),
                  _c("div", { staticClass: "cat-whiskers" }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "cat-lower-wrap" }, [
              _c("div", { staticClass: "cat-lower" }, [
                _c("div", { staticClass: "cat-leg" }, [
                  _c("div", { staticClass: "cat-leg" }, [
                    _c("div", { staticClass: "cat-leg" }, [
                      _c("div", { staticClass: "cat-leg" }, [
                        _c("div", { staticClass: "cat-leg" }, [
                          _c("div", { staticClass: "cat-leg" }, [
                            _c("div", { staticClass: "cat-leg" }, [
                              _c("div", { staticClass: "cat-leg" }, [
                                _c("div", { staticClass: "cat-leg" }, [
                                  _c("div", { staticClass: "cat-leg" }, [
                                    _c("div", { staticClass: "cat-leg" }, [
                                      _c("div", { staticClass: "cat-leg" }, [
                                        _c("div", { staticClass: "cat-leg" }, [
                                          _c(
                                            "div",
                                            { staticClass: "cat-leg" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "cat-leg" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cat-leg" },
                                                    [
                                                      _c("div", {
                                                        staticClass: "cat-paw",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "cat-leg" }, [
                  _c("div", { staticClass: "cat-leg" }, [
                    _c("div", { staticClass: "cat-leg" }, [
                      _c("div", { staticClass: "cat-leg" }, [
                        _c("div", { staticClass: "cat-leg" }, [
                          _c("div", { staticClass: "cat-leg" }, [
                            _c("div", { staticClass: "cat-leg" }, [
                              _c("div", { staticClass: "cat-leg" }, [
                                _c("div", { staticClass: "cat-leg" }, [
                                  _c("div", { staticClass: "cat-leg" }, [
                                    _c("div", { staticClass: "cat-leg" }, [
                                      _c("div", { staticClass: "cat-leg" }, [
                                        _c("div", { staticClass: "cat-leg" }, [
                                          _c(
                                            "div",
                                            { staticClass: "cat-leg" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "cat-leg" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "cat-leg" },
                                                    [
                                                      _c("div", {
                                                        staticClass: "cat-paw",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "cat-tail" }, [
                  _c("div", { staticClass: "cat-tail" }, [
                    _c("div", { staticClass: "cat-tail" }, [
                      _c("div", { staticClass: "cat-tail" }, [
                        _c("div", { staticClass: "cat-tail" }, [
                          _c("div", { staticClass: "cat-tail" }, [
                            _c("div", { staticClass: "cat-tail" }, [
                              _c("div", { staticClass: "cat-tail" }, [
                                _c("div", { staticClass: "cat-tail" }, [
                                  _c("div", { staticClass: "cat-tail" }, [
                                    _c("div", { staticClass: "cat-tail" }, [
                                      _c("div", { staticClass: "cat-tail" }, [
                                        _c("div", { staticClass: "cat-tail" }, [
                                          _c(
                                            "div",
                                            { staticClass: "cat-tail" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "cat-tail" },
                                                [
                                                  _c("div", {
                                                    staticClass:
                                                      "cat-tail -end",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }