var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "div",
        {
          staticClass: "container mt-20",
          staticStyle: { "max-width": "800px", "padding-bottom": "50px" },
        },
        [
          _c("payment-option-step", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPaymentOptions,
                expression: "showPaymentOptions",
              },
            ],
            attrs: {
              currencyCode: _vm.currencyCode,
              countryCode: _vm.countryCode,
              locale: _vm.locale,
              product: _vm.product,
              paymentPlan: _vm.paymentPlan,
            },
          }),
          _c("payment-step", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === "PAYMENT",
                expression: "step === 'PAYMENT'",
              },
            ],
            attrs: {
              paymentPlan: _vm.paymentPlan,
              paymentMethod: _vm.paymentMethod,
              currencyCode: _vm.currencyCode,
              countryCode: _vm.countryCode,
              authClient: _vm.authClient,
              locale: _vm.locale,
              eventBus: _vm.eventBus,
              paymentBrokerAccountId: _vm.paymentBrokerAccountId,
            },
            on: {
              back: function ($event) {
                return _vm.updateStep("OPTION")
              },
              "express-payment-method-success":
                _vm.onExpressPaymentMethodSuccess,
              "express-payment-created": _vm.onExpressPaymentSuccess,
              next: _vm.goToReviewStep,
            },
          }),
        ],
        1
      ),
      _vm.step === "REVIEW"
        ? _c("review-step", {
            attrs: {
              paymentPlan: _vm.paymentPlan,
              paymentMethod: _vm.paymentMethod,
              currencyCode: _vm.currencyCode,
              countryCode: _vm.countryCode,
              locale: _vm.locale,
              isExpressPayAuthorized: _vm.isExpressPayAuthorized,
              eventBus: _vm.eventBus,
              address: _vm.address,
              applicationId: _vm.applicationId,
              fsClient: _vm.fsClient,
            },
            on: {
              back: function ($event) {
                return _vm.updateStep("PAYMENT")
              },
              next: _vm.goToSummaryStep,
            },
          })
        : _vm._e(),
      _vm.step === "SUMMARY"
        ? _c("summary-step", { attrs: { payment: _vm.payment } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }